class Svg2Canvas {
  constructor(svg, options) {
    this.svg = svg
    this.options = {
      width: options.width || 100,
      height: options.height || 100,
      ratio: window.devicePixelRatio || options.ratio || 2,
    }
  }

  async init() {
    const { width, height, ratio } = this.options
    const img = await this.loadImage()
    this.canvas = document.createElement('canvas')
    this.canvas.width = width * ratio
    this.canvas.height = height * ratio
    const ctx = this.canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, width * ratio, height * ratio)
  }

  async loadImage() {
    return new Promise((resolve, reject) => {
      const { width, height, ratio } = this.options
      const img = new Image(width * ratio, height * ratio)
      img.src = this.svg
      img.onload = () => {
        resolve(img)
      }
      img.onerror = () => {
        reject(new Error(`Error loading: ${this.src}`))
      }
    })
  }

  async toDataURL(type = 'image/png') {
    if (!this.canvas) {
      await this.init()
    }
    return this.canvas.toDataURL(type)
  }
}

export default Svg2Canvas
