<template lang="pug">
  div(:class="$style.wrapper")
    Object(ref="material", :item="item", v-for="item in items", :key="item.eid")
    div(:class="$style.hide")
</template>
<style module>
  .wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -10000;
    overflow: hidden;
  }
  .hide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
  }
</style>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { GET_PROJECT } from '@/store/modules/projects/types'
import ArScene from '@/components/Vr/ArScene3.vue'
// import ArScene from '@/components/Vr/VrScene2.vue'
import Object from '@/components/Vr/Object.vue'
import router from '@/router'
// import Object2Canvas from '@/utils/Object2Canvas'
import Svg2Canvas from '@/utils/Svg2Canvas'
import SvgTextArea from '@/utils/SvgTextArea'
import SocialIcon from '@/utils/SocialIcon'
import ItemBlockController from '@/utils/ItemBlockController'

export default {
  name: 'ProjectPreview',
  components: {
    Object,
  },
  computed: {
    ...mapState('projects', {
      project: 'project',
    }),
    items() {
      if (this.project && this.project.items) {
        return this.project.items
      }
      return []
    },
  },
  data() {
    return {
      show: false,
      text: null,
    }
  },
  async mounted() {
    const { id } = this.$route.params
    await this.GET_PROJECT({ id, type: 'uid' })
    if (this.$route.query.text) {
      this.text = this.$route.query.text
    }
    const el = document.body.appendChild(document.createElement('div'))
    const promise = async (item, index) => {
      // const object2Canvas = new Object2Canvas(this.$refs.material[index].$el)
      // await object2Canvas.init()
      // const src = await object2Canvas.toDataURL('image/png')
      const src = await this.getItemSrc(item, index)
      return {
        ...item,
        src,
      }
    }
    const objects = await Promise.all(this.items.map(promise))
    new Vue({
      router,
      render: h => h(ArScene, {
        props: {
          objects,
        },
      }),
    }).$mount(el)
  },
  beforeMount() {
  },
  methods: {
    ...mapActions('projects', {
      GET_PROJECT,
    }),
    async getItemSrc(item, index) {
      if (item.type === 'text') {
        const svgText = new SvgTextArea(item.value, this.svgOptions(item))
        const drawer = document.createElement('div')
        document.body.appendChild(drawer)
        svgText.addTo(drawer)
        // svgText.generate()
        const blob = new Blob([svgText.svg.node.outerHTML], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(blob)
        console.log(url)
        drawer.remove()
        const itemBlock = new ItemBlockController(item)
        const { width, height } = itemBlock.convertBrowserSize()
        const ratio = window.devicePixelRatio
        const svg2Canvas = new Svg2Canvas(url, { width, height, ratio })
        console.log(svg2Canvas)
        return svg2Canvas.toDataURL('image/png')

        // const object2Canvas = new Object2Canvas(this.$refs.material[index].$el)
        // await object2Canvas.init()
        // const src = await object2Canvas.toDataURL('image/png')
        // return src
      }
      if (item.type === 'social') {
        const itemBlock = new ItemBlockController(item)
        const { width, height } = itemBlock.convertBrowserSize()
        const { src } = (new SocialIcon(item.value))
        const ratio = window.devicePixelRatio
        const svg2Canvas = new Svg2Canvas(src, { width, height, ratio })
        console.log(svg2Canvas)
        return svg2Canvas.toDataURL('image/png')
      }
      return ''
    },
    svgOptions(item) {
      const itemBlock = new ItemBlockController(item)
      const { width, height } = itemBlock.convertBrowserSize()
      const anchor = (() => {
        switch (item.align) {
          case 'left':
            return 'start'
          case 'center':
            return 'middle'
          case 'right':
            return 'end'
          default:
            return ''
        }
      })()
      return {
        width: width - (item.padding_left + item.padding_right),
        height: height - (item.padding_top + item.padding_bottom),
        fontSize: item.font_size,
        color: item.color,
        backgroundColor: item.background_color,
        strokeColor: item.stroke_color,
        strokeWidth: item.stroke_width,
        anchor,
      }
    },
  },
  metaInfo() {
    return {
      title: 'QRコード型WebAR QRAR',
      titleTemplate: '',
      meta: [
        {
          vmid: 'viewport',
          name: 'viewport',
          content: 'width=device-width, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0',
        },
        { vmid: 'robots', name: 'robots', content: 'noindex' },
      ],
    }
  },
}
</script>
